/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { MobileVerticalDotsMajor } from '@shopify/polaris-icons';
import { ActionList, Button, Popover } from '@shopify/polaris';
import { gql, useMutation } from '@apollo/client';

const MUTATION_CREATE = gql`
  mutation createCoreProduct($itemId: [Int]) {
    createCoreProduct(itemId: $itemId)
  }
`;

const MUTATION_UPDATE = gql`
  mutation removeCoreProduct($itemId: [Int]) {
    removeCoreProduct(itemId: $itemId)
  }
`;

const MUTATION = gql`
  mutation setProductRestock($setProductRestockResolverId: Int!) {
    setProductRestock(id: $setProductRestockResolverId)
  }
`;

export function MenuRowFieldMaker({ item }: { item: any }) {
  const [active, setActive] = useState(false);
  const [createCoreProduct] = useMutation(MUTATION_CREATE, {
    refetchQueries: ['productList'],
  });
  const [removeCoreProduct] = useMutation(MUTATION_UPDATE, {
    refetchQueries: ['productList'],
  });
  const [setProductRestock] = useMutation(MUTATION, {
    refetchQueries: ['productList'],
  });

  const toggleActive = useCallback(() => setActive(active => !active), []);

  const menu = [];

  if ((item as any).inputDate) {
    menu.push({ content: `Uploaded at ${(item as any).inputDate}`, disabled: true });
  }

  if ((item as any).lastStockIn) {
    menu.push({ content: `Last stock at ${(item as any).lastStockIn}`, disabled: true });
  }

  if ((item as any).followedBy) {
    menu.push({ content: `Followed by ${(item as any).followedBy.name}`, disabled: true });
  }

  useEffect(() => {
    (document.getElementById('btn_menurow' + item.id) as any).style.borderStyle = 'none';
    (document.getElementById('btn_menurow' + item.id) as any).style.backgroundColor = 'transparent';
  }, []);

  const activator = (
    <Button id={'btn_menurow' + item.id} icon={MobileVerticalDotsMajor} onClick={toggleActive}></Button>
  );

  const onClickCoreProduct = () => {
    const confirm = window.confirm(
      `You have product code : ${item.code} are ${item.isCore ? 'remove' : 'set'} core product.`,
    );

    if (confirm) {
      if (item.isCore) {
        removeCoreProduct({
          variables: {
            itemId: Number(item.id),
          },
        });
      } else {
        createCoreProduct({
          variables: {
            itemId: Number(item.id),
          },
        });
      }
    }
  };

  const onClickRestock = () => {
    const confirm = window.confirm(
      `You have product code : ${item.code} are ${item.isRestock ? 'remove' : 'set'} restock product.`,
    );

    if (confirm) {
      setProductRestock({
        variables: {
          setProductRestockResolverId: item.id,
        },
      });
    }
  };

  return (
    <div>
      <Popover activator={activator} active={active} autofocusTarget="first-node" onClose={toggleActive}>
        <ActionList
          actionRole="menuitem"
          items={[
            ...menu,
            ...[
              {
                content: 'New Edit',
                onAction: () => {
                  window.open(`/product/edit/${item.id}`, '_blank');
                  window.focus();
                },
              },
              { content: item.isCore ? 'Romove Core Product' : 'Set as Core Product', onAction: onClickCoreProduct },
              {
                content: item.isRestock ? 'Remove Restock Product' : 'Set as Restock Product',
                onAction: onClickRestock,
              },
              {
                content: 'Add Fee',
                onAction: () => {
                  window.open(`/supplier/detail/${item.supplier.id}?code=${item.code}#punishment_Fee`, '_blank');
                  window.focus();
                },
              },
            ],
          ]}
        />
      </Popover>
    </div>
  );
}
