import React, { useEffect, useState } from 'react';
import styles from './tasks.module.scss';
import { useQuery } from '@apollo/client';
import { Graph } from '../../../generated/graph';
import { QUERY_PACKAGE_LIST } from '../query';
import { Link } from 'react-router-dom';
import { BlockLoading } from '../../../components/loading/BlockLoading';
import { Table } from 'src/components/layout/tables/Table';
import { useHistory, useLocation } from 'react-router';
import { LinkButton } from 'src/components/layout/xform/LinkButton';
import Button from 'src/components/layout/xform/Button';
import { useQueryString } from 'src/libs/QueryString';
import { Checkbox } from 'src/components/form/FormComponent';
import XForm from 'src/components/layout/xform/XForm';
import Formatter from 'src/libs/Formatter';

type DeliveringTabName = 'DELIVERY' | 'THIRD_PARTY' | 'All' | 'COD_THIRD_PARTY';

function renderPackage(item: Graph.Package, idx: number) {
  return (
    <tr key={item.id}>
      <td>{idx + 1}</td>
      <td className="text-center">
        <i
          className={item.isReturning ? 'fas fa-arrow-left' : 'fas fa-arrow-right'}
          style={{ fontSize: 20, color: item.isReturning ? '#e74c3c' : '#27ae60' }}
        ></i>
      </td>
      <td>{item.type === 'THIRD_PARTY' ? 'Third Party' : 'L192'}</td>
      <td>
        <strong>{item.supplier?.name}</strong>
      </td>
      <td>
        <Link to={`/package/detail/${item.id}`}>{item.id.toString().padStart(8, '0')}</Link>
        {Array.isArray(item.notes) && item.notes.length > 0 ? (
          <>
            {item?.notes?.slice(-1).map(x => (
              <div className={styles.notesBox} key={x.id}>
                <p className="mb-0">
                  <i>{x.message || null}</i>
                </p>
                <i>({x.createdAt || null})</i>
              </div>
            ))}
          </>
        ) : null}
      </td>
      <td>
        {item.customer && (
          <div>
            <strong>{item.customer.name}</strong>
            <br />
            <span>{item.customer.phoneNumber}</span>
          </div>
        )}
      </td>
      <td>{item.claimDeliveryBy && <strong>{item.claimDeliveryBy?.name}</strong>}</td>
      <td>
        <strong style={{ fontSize: 13 }}>{item.currentWarehouse?.shortName}</strong>
      </td>
      <td>
        <strong>{item.createdAt}</strong>
        <br />
        <strong>{item.appointedAt}</strong>
      </td>
      <td style={{ textAlign: 'right' }}>
        <strong>{Formatter.currency(item?.fee)}</strong>
      </td>
    </tr>
  );
}
function PacakgeList({ items, group }: { items: Graph.Package[]; group: boolean }) {
  const [toggle, setToggle] = useState(!group);
  return (
    <Table stripe={true} border={true}>
      <thead>
        {group && (
          <tr style={{ background: '#ced6e0' }} onClick={() => setToggle(!toggle)}>
            <th colSpan={9}>
              {items[0].supplier?.name || 'Little Fashion'}
              <span style={{ fontWeight: 'lighter' }}> ({items.length})</span>
            </th>
            <th style={{ textAlign: 'right' }}>
              <XForm.Button>{toggle ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</XForm.Button>
            </th>
          </tr>
        )}
        {toggle && (
          <tr>
            <th style={{ width: 40 }}>#</th>
            <th style={{ width: 75 }}>Type</th>
            <th style={{ width: 100 }}>Services</th>
            <th style={{ width: 200 }}>Merchant</th>
            <th>Package</th>
            <th style={{ width: 100 }}>Customer</th>
            <th style={{ width: 200 }}>Claimed By</th>
            <th style={{ width: 100 }}>From</th>
            <th style={{ width: 210 }}>Created At/Appointed At</th>

            <th style={{ width: 100, textAlign: 'right' }}>Fee</th>
          </tr>
        )}
      </thead>

      {toggle && <tbody>{items.map(renderPackage)}</tbody>}
    </Table>
  );
}

function renderPackageBreakdownBySupplier(items: Graph.Package[], groupBySeller: boolean) {
  const uniqueSuppliers = new Set(items.map(x => (x.supplier ? x.supplier.id : null)));
  const suppliersList = [...uniqueSuppliers];

  if (groupBySeller) {
    return suppliersList.map((id, i) => {
      const orders = items.filter(x => {
        if (!id) return x.supplier === null;
        return x.supplier?.id === id;
      });

      return (
        <div key={i} className="mb-3">
          <PacakgeList items={orders} group={groupBySeller} />
        </div>
      );
    });
  }

  return (
    <div className="mb-3">
      <PacakgeList items={items} group={groupBySeller} />
    </div>
  );
}

function PackageButtonFilter({ items, groupBySeller }: { items: Graph.Package[]; groupBySeller: boolean }) {
  const [thirdParty, setThirdParty] = useState('All');

  let codThirParty = 0;

  const third: { title: string; total: number }[] = [{ title: 'All', total: items.length }];
  items.map(x => {
    const title = x.type || '';
    const index = third.findIndex(h => h.title === title);
    if (index < 0) {
      third.push({ title, total: 1 });
    } else {
      const hub = third[index];
      third[index] = { title, total: hub.total + 1 };
    }
    if (x.isThirdPartyCod) {
      codThirParty += 1;
    }
    return null;
  });

  if (codThirParty > 0) {
    third.push({ title: 'COD_THIRD_PARTY', total: codThirParty });
  }

  const tabName = {
    DELIVERY: 'City',
    THIRD_PARTY: ' Third Party',
    All: 'All',
    COD_THIRD_PARTY: 'COD Third Party',
  };

  let packagesToRender = items;
  if (thirdParty === 'COD_THIRD_PARTY') {
    packagesToRender = items.filter(item => item.isThirdPartyCod);
  } else if (thirdParty !== 'All') {
    packagesToRender = items.filter(item => item.type === thirdParty);
  }
  return (
    <>
      <div className={styles.btn_filter_wrap}>
        {third.map((h, i) => (
          <div key={i}>
            <Button
              theme={thirdParty === h.title ? 'primary' : 'secondary'}
              style={{ marginRight: '10px' }}
              value={h.title}
              onClick={() => setThirdParty(h.title)}
            >
              {tabName[h.title as DeliveringTabName]} ({h.total})
            </Button>
          </div>
        ))}
      </div>
      {renderPackageBreakdownBySupplier(packagesToRender, groupBySeller)}
    </>
  );
}

export function PackageDeliveringListScreen() {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const qs = useQueryString<{
    groupBySeller: '1' | '0';
  }>({
    groupBySeller: '0',
  });
  const [groupBySeller, setGroupBySeller] = useState(qs.groupBySeller === '1');

  const query = `${pathname}?groupBySeller=${groupBySeller ? '1' : '0'}`;
  useEffect(() => {
    history.replace(query + '#delivering');
  }, [search, query, history]);

  const { data } = useQuery<Graph.Query>(QUERY_PACKAGE_LIST, { variables: { status: ['DELIVERING'] } });
  if (!data?.packageList) return <BlockLoading />;
  return (
    <div>
      <div className={styles.CheckboxWrap}>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #555',
            color: '#3E4B5B',
          }}
        >
          <div>
            <Checkbox
              label="Group by Seller"
              checked={groupBySeller}
              onChange={e => {
                setGroupBySeller(e.target.checked);
              }}
            />
          </div>
        </div>

        <div>
          <LinkButton to={'map'} style={{ borderRadius: '15px', height: 28 }}>
            Map
          </LinkButton>
        </div>
      </div>
      <hr />
      <PackageButtonFilter items={data.packageList} groupBySeller={groupBySeller} />
    </div>
  );
}
