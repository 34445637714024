import React, { useState } from 'react';
import { Graph } from 'src/generated/graph';
import style from './style.module.scss';
export default function MessagePopup({ dataMsg, id }: { dataMsg: Graph.GeneralEventAdded | undefined; id: number }) {
  const [show, setShow] = useState<string>();
  return (
    <div className={style.msg_wrap} key={id} style={{ display: id === Number(show) ? 'none' : '' }}>
      <div className={`${style.content} ${'box-control fading-left'}`}>
        <div
          className={style.content_inner}
          onClick={() => {
            window.open(dataMsg?.payload, '_blank');
          }}
        >
          <span
            className={style.removeBtn}
            id={id.toString()}
            onClick={e => {
              e.stopPropagation();
              setShow(e.currentTarget.id);
            }}
          >
            <i className="fas fa-times" style={{ color: '#fff' }}></i>
          </span>
          <span className={style.icon_bell}>
            <i className="fas fa-bell" style={{ fontSize: '30px', color: '#fff' }}></i>
          </span>
          <span className={style.msg}>
            <span>{dataMsg?.type && dataMsg?.type.replace(/(?=[A-Z])/g, ' ').toLowerCase()}</span>
          </span>
        </div>
      </div>
    </div>
  );
}
